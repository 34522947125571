import React from 'react'
import utils from './utils'
import consts from './consts'
import restUtils from './restUtils'

async function useGetTemplates(dispatch, onCompletion){
  try {
    let res = await restUtils.post('getTemplates', null, true);
    if(res.data) {
      let siteTemplateFromServer = res.data.find(t => t.type === 'site').data;
      let emailTemplateFromServer = res.data.find(t => t.type === 'email').data;
      dispatch({ type: 'SITE-TEMPLATE-CHANGED', payload: siteTemplateFromServer});
      dispatch({ type: 'EMAIL-TEMPLATE-CHANGED', payload: emailTemplateFromServer});
      onCompletion && onCompletion();
      return res.data;
    }
  } catch (error) {
    console.log(error);
    if(error && error.response && error.response.status==401){
      utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.IS_LOGGED_IN, null, 365); //update local storage is logged in value to false. TODO: add this logic to axios generic 401 interceptor
      window.location.assign('/posts/login');
    }
  }
}

export {useGetTemplates}
