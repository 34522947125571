
/* eslint-disable react/react-in-jsx-scope */
import style from './mockForm.module.css'

export default function MockForm({formColor, showPoweredBy, normalScale, formStyle})  {

  function renderMockFileItem() {
    return (
      <div className={style.mockFileItemWrapper}>
      <div className={normalScale ? style.mockFileItemBig : style.mockFileItem}>
      <div className={normalScale ? style.mockFileItemNamePlaceHolderBig : style.mockFileItemNamePlaceHolder}/>
      <div className={normalScale ? style.mockFormFileSizePlaceHolderBig : style.mockFormFileSizePlaceHolder}/>
      </div>
      <div className={normalScale ? style.downloadButtonBig : style.downloadButton}>
      <svg xmlns="http://www.w3.org/2000/svg" width={normalScale ? "22": '14'} height={normalScale ? "22": '14'} viewBox="0 0 34 34" fill="none">
        <circle cx="17" cy="17" r="17" fill={formColor}/>
        <path d="M17.002 24.655V10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24 19.3245L17 24.6536L10 19.3245" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
      </div>
      </div>
    );
  }

  function renderPoweredBy() {
    return (
     <div className={style.poweredByContainer}>
        <span className={style.poweredByText}>Powered By FileDriver.com</span>
        <a className={style.poweredByLink}>Share your files</a>
     </div>

    );
  }

  function renderMockForm() {
    return (
        <div style={formStyle} className={normalScale ? style.formWrapperBig : style.formWrapper}>
        <div className={normalScale ? style.mockFormContainerBig : style.mockFormContainer}>
         <div className={normalScale ? style.mockFormTitlePlaceHolderBig : style.mockFormTitlePlaceHolder}></div> 
        <div className={normalScale ? style.mockFormFilesListBig : style.mockFormFilesList}>
        {[1,2,3,4 ].map(file => {return renderMockFileItem(file)})}
        </div>
        <button disabled style={{backgroundColor: formColor}} className={normalScale ? style.mockFormDownloadAllButtonBig : style.mockFormDownloadAllButton}>Download All</button>
      </div>
        {showPoweredBy && renderPoweredBy()}
        </div>
    );
  }

  return (
    renderMockForm()
  )
  }