/* eslint-disable react/react-in-jsx-scope */
import {useEffect} from 'react'
import style from './loader.module.css'
export default function Loader({color, loaderStyle})  {   
    return (
        <div style={loaderStyle} className={style.ldsHeart}>
        <div className={color ? style[`ldsHeartInner${color.substring(1,color.length)}`] : style.ldsHeartInner}>
          </div>
          </div>
    );
};
