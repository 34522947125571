
/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import { useEffect, useState, useRef } from 'react';
import style from './brandPreview.module.css'
import utils from './utils'
import consts from './consts'
import {useGetTemplates} from './useGetTemplates'
import { useStateValue } from '../state';
import Loader from './loader'
import StyledToolTip from './styledToolTip'
import CloseIcon from './closeIcon'
import EyeIcon from './eyeIcon';
import BrandedBadge from './brandedBadge';

export default function BrandPreview()  {
    const [templatesLoaded, updateTemplatesLoaded] = useState(false);
    const [showTrialUpsell, updateShowTrialUpsell] = useState(false);
    const wrapperRef = useRef(null);
    const showUpsellTooltip = false;

    const [
      {siteTemplate, user},
      dispatch
    ] = useStateValue();

    function oneEditBrandClick() {
      utils.trackEvent(consts.TRACKING_CATEGORY.CUSTOM_BRAND_EDITOR, 'click','edit_brand_link');
    }

  useEffect(() => {
    async function getTemplates() {
      await useGetTemplates(dispatch, () => {
        updateTemplatesLoaded(true);
      });
      
    }

    async function refreshTemplates() {
      let templateChanged = utils.getLocalStorageItem(consts.LOCAL_STORAGE_KEYS.BRAND_CHANGED);
      if(templateChanged) {
        updateTemplatesLoaded(false);
        await getTemplates();
        utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.BRAND_CHANGED, false);
      }
    }

     getTemplates();

     window.addEventListener('storage', refreshTemplates)
  
     return () => {
       window.removeEventListener('storage', refreshTemplates)
     }
  }, [])

  useEffect(() => {
    function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        updateShowTrialUpsell(false);
        }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, [wrapperRef]);

    function handleOnBadgeMouseEnter() {
      updateShowTrialUpsell(true);
    }

    function renderPreviewSnapshot() {
      return(
        siteTemplate.brandPreviewSnapshot && <img className={style.brandImageLink} src={`data:image/jpeg;base64,${siteTemplate.brandPreviewSnapshot}`}></img>
      );
    }

    function renderEditIcon() {
      return(
<svg className={style.editIcon} xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
<path d="M1 18H5.4362L17.0812 6.35497C17.6695 5.76669 18 4.96882 18 4.13687C18 3.30492 17.6695 2.50704 17.0812 1.91877C16.493 1.33049 15.6951 1 14.8631 1C14.0312 1 13.2333 1.33049 12.645 1.91877L1 13.5638V18Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.5356 3.02832L15.9718 7.46452" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
      );
    }

    function onUpgradeClick() {
      utils.setSessionStorageItem(consts.SELECTED_PACKAGE.PRICE, consts.PACKAGE_PRICES.BUSINESS); 
      updateShowTrialUpsell(false);
    }

    function renderTrialUpsell() {
      return (
        <div ref={wrapperRef} className={style.trialUpsell}>
          <CloseIcon onClick={() => updateShowTrialUpsell(false)} iconStyle={{
          width: "20px", 
          height: "20px",
          cursor: "pointer",
          position: 'absolute',
          top: '0px',
          right: '0px',
          marginTop: '10px',
          marginRight: '10px',
          zIndex: 10,
          opacity: 0.5,
       }} svgStyle={{position: 'relative', left: '0.5px', top: '0.5px'}}/>
        <span className={style.trialUpsellText}>
      This is a business plan feature. <br/> You have <b>{user.trialInfo.daysRemain}</b> days left on your trial
        </span>
        <a onClick={onUpgradeClick} href={`/posts/pricing/?source=trial-upgrade-cta&upgrade=true`} style={{textDecoration: 'none'}} className={style.trialUpsellLink}>
        <span className={style.trialUpsellLinkText}>
         Upgrade
        </span>
        </a>
      </div>
      );
    }

    function renderUpgradeMessage() {
      return (
        <div className={style.upgradeMessageContainer}>
          <span className={style.upgradeMessageText}>This is a business plan feature.<br/> You have <b>{user.trialInfo.daysRemain}</b> days left on your trial</span>
        <a onClick={onUpgradeClick} href={`/posts/pricing/?source=trial-upgrade-cta&upgrade=true`} style={{textDecoration: 'none'}} className={style.trialUpsellVisibleLink}>
        <span className={style.trialUpsellVisibleLinkText}>
         Upgrade Plan
        </span>
        </a>
        </div>
      )
    }

    function renderBrandPreview() {
      return (
         <div className={user.trialInfo.onTrial ? style.brandPreviewContainerOnTrial : style.brandPreviewContainer}>
          { templatesLoaded ?
          <React.Fragment>
          {false && user.trialInfo.onTrial && renderUpgradeMessage()}
          <a target="_blank" rel="noopener noreferrer" onClick={oneEditBrandClick} href={`/posts/brand-editor?userId=${user.userId}`} style={{textDecoration: 'none'}} className={user.trialInfo.onTrial ? style.editYourBrandLinkOnTrial : style.editYourBrandLink}>
            <div className={style.editYourBrandLinkWrapper}>
              {renderEditIcon()}
              <span className={style.editYourBrandLinkText}>Edit your brand</span>
            </div>
          </a>
          <StyledToolTip tooltipContentStyle={{padding: '0'}} pointerStyle={{top: '5%'}} tooltipStyle={{position: 'absolute', top: '30px', left: '550px', width: '299px', minHeight: '138px', maxHeight: '138px'}} 
          show={showTrialUpsell && user.trialInfo.onTrial && showUpsellTooltip}
          shouldRenderPointer={true} fadeOut={false}>
            {renderTrialUpsell()}
         </StyledToolTip>
          <BrandedBadge brandedBadgeStyle={{bottom: user.trialInfo.onTrial && '79', left: user.trialInfo.onTrial && '442px', width: user.trialInfo.onTrial && '100px', height: user.trialInfo.onTrial && '100px'}} oBadgeMouseEnter={handleOnBadgeMouseEnter}/>
          <a href={`${window.location.origin}/?userId=${user.userId}&demoSite=true`} className={style.viewDemoLink} target="_blank" rel="noopener noreferrer">
            <div className={style.viewDemoLinkInnerContainer}>
              <EyeIcon iconStyle={{marginRight: '8px', position: 'relative', top: '1px'}} iconColor={'white'} width={"18"} height={"12"}/>
              <span className={style.viewDemoLinkText}>View what your recipients will see</span>
            </div>
          </a>
          <a target="_blank" rel="noopener noreferrer" className={style.previewBrandImage} href={`${window.location.origin}/?userId=${user.userId}&demoSite=true`}>
          {renderPreviewSnapshot()}
           </a>
          </React.Fragment>
        :
        <div style={{display: 'block', position: 'relative', top: '100px'}}> 
          <Loader loaderStyle={{margin: '0', padding: '0', width:'100px', height: '100px', left: '220px'}} color={siteTemplate.complementaryColor}/>
          <span className={style.loadingText}>Loading your brand...</span> 
          </div>}
        </div> 
      );
    }

    return (
      renderBrandPreview()
    ); 
}